import { FC, ReactElement, useRef } from 'react';
import showdown from 'showdown';
import { Container } from './Container';
import { Subtitle } from './Subtitle';
import { useAnimation } from '../hooks';
import { setAnimationConfig } from '../hooks/useAnimation';
import { addRedSpan, removeParagraph } from '../helpers/showdown';

interface IHero {
  media: ReactElement;
  title: string;
  subtitle?: string;
  extraInfo?: ReactElement | string;
  shouldShowShare?: boolean;
  isInContainer?: boolean;
  className?: string;
}

interface IHeroTitle {
  title: string;
  className?: string;
  animation?: string;
}

export const HeroTitle: FC<IHeroTitle> = ({
  title,
  className,
  animation,
}): JSX.Element => {
  const converter = new showdown.Converter({
    extensions: [addRedSpan, removeParagraph],
  });
  const parsedTitle = converter.makeHtml(title);

  return (
    <h2
      className={`font-barlow font-bold text-5xl xl:text-7xl lg:6xl leading-[3.125rem] pb-6 tracking-[-1px] ${className}`}
      dangerouslySetInnerHTML={{ __html: parsedTitle }}
      {...(animation && { 'data-animation': animation })}
    />
  );
};

export const Hero: FC<IHero> = ({
  media,
  title,
  subtitle,
  extraInfo = null,
  isInContainer = false,
  className = '',
}) => {
  const containerRef = useRef(null);
  useAnimation({
    containerRef,
  });

  const infoContainerDimensions = isInContainer
    ? 'lg:my-[8%] lg:w-[40%] lg:h-fit'
    : 'lg:self-center lg:w-[min(724px,53%)]';

  const imageDimensions = isInContainer
    ? 'lg:left-[35%]'
    : 'lg:left-[calc(((100vw-min(100vw,1366px))/2)+calc(min(100vw,1366px)*0.45))]';

  if (!title) {
    return null;
  }

  return (
    <div className={`flex relative ${className}`} ref={containerRef}>
      <Container
        fullWidth
        className={`h-full lg:min-h-[max(552px,35vh)] flex flex-col lg:flex-row flex-1 lg:pl-6 ${
          !isInContainer && 'lg:ml-[calc((100vw-min(100vw,1366px))/2)]'
        } z-10`}
      >
        <div
          className={`${infoContainerDimensions} relative px-6 pt-6 pb-8 lg:pl-0 z-10`}
        >
          <div
            className="absolute top-0 left-0 w-full h-full bg-white z-[-1] invisible"
            data-animation={setAnimationConfig({
              type: 'slide-in',
              delay: 6,
              duration: 4,
              from: 'left',
            })}
          />
          <div
            className="invisible"
            data-animation={setAnimationConfig({
              type: 'slide-in',
              delay: 3,
              duration: 4,
              from: 'right',
            })}
          >
            <HeroTitle
              className="invisible"
              title={title}
              animation={setAnimationConfig({
                type: 'clip',
                duration: 6,
                from: 'bottom',
              })}
            />
          </div>
          {subtitle && (
            <Subtitle
              className="invisible"
              animation={setAnimationConfig({
                type: 'slide-in-fade',
                delay: 7,
                duration: 4,
                from: 'left',
              })}
            >
              {subtitle}
            </Subtitle>
          )}
          {extraInfo && (
            <div
              className="mt-4 lg:absolute lg:top-full lg:w-[75%] lg:mt-[-1rem] invisible"
              data-animation={setAnimationConfig({
                type: 'slide-in-fade',
                delay: 8,
                duration: 4,
                from: 'left',
              })}
            >
              {extraInfo}
            </div>
          )}
        </div>
        <div
          className={`${imageDimensions} flex items-center justify-center relative h-[300px] lg:h-full lg:absolute lg:right-0 lg:top-0 lg:bottom-0 invisible max-w-hero`}
          data-animation={setAnimationConfig({
            type: 'clip',
            delay: 10,
            duration: 4,
            from: 'right',
          })}
        >
          {media}
        </div>
      </Container>
    </div>
  );
};
